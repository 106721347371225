@import '../../styles/vars.scss';
@import '../../styles/theme.scss';

.withdrawal{
    flex: 12;
    border-radius: 32px;
    border: 1px solid var(--border-main);
    background: var(--background-2);
    @include flex(center,start);
    flex-direction: column;
    .withdrawal_title{
        border-bottom: 1px solid var(--border-main);
        padding: 2.4vh;
        width: calc(100% - 4.8vh);
        @include flex(center,start);
        gap: 0.8vh;
        color: var(--nav-button-font-active);
        font-size: 1.6vh;
        font-weight: 500;
        line-height: 1.6vh;
        a{
            @include flex(center,start);
            gap: 0.8vh;
            color: var(--font-sub-font);
            font-size: 1.6vh;
            font-weight: 500;
            line-height: 1.6vh; 
            text-decoration: none;
            path{
                fill: var(--font-sub-font);
            }
        }
    }
    .withdrawal_body{
        width: calc(100% - 2.4vh);
        padding: 2.4vh;
        height: 100%;
        @include flex(stretch,start);
        gap: 12px;
        .templates_body{
            padding: 2.4vh;
            flex: 0.22;
            margin-top: 4.6vh;
            margin-bottom: 4.6vh;
            @include flex(center,start);
            flex-direction: column;
            margin-left: 10vh;
            gap: 1.2vh;
            max-height: 60vh;
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
                width: 0; // Устанавливаем ширину для скрытия в WebKit-браузерах
                height: 0;
            }
        
            // Для Firefox
            scrollbar-width: none; // Убираем ширину прокрутки
            -ms-overflow-style: none; 
            .template{
                width: 100%;
                @include flex(center,start);
                padding: 1.6vh;
                flex-direction: column;
                border-radius: 32px;
                background: var(--background-3, #1D1D20);
                border: 1px solid var(--background-3);
                gap: 1.6vh;
                &.active {
                    transition: all 0.15s ease-in;
                    border: 1px solid var(--Other-Purple, #A855F7);
                }
                .types{
                    width: 100%;
                    @include flex(center,space-between);
                    .type{
                        @include flex(center,start);
                        gap: 0.8vh;
                        border-radius: 9999px;
                        background: var(--global-dark-800, #26272B);
                        padding: 0.4vh 1.2vh;
                        color: var(--Global-Light-25, #FCFCFD);
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.24px;
                    }
                    .card_type{
                        @include flex(center,center);
                        gap: 0.8vh;
                        .delete_icon{
                            padding: 1.2vh;
                            border-radius: var(--Corner-Full, 1000px);
                            border: 1px solid var(--border-main, #26272B);
                            background: var(--global-dark-700, #3F3F46);
                            cursor: pointer;
                        }
                    }
                }
                .value_amount{
                    width: 100%;
                    @include flex(center,space-between);
                    .value_label{
                        @include flex(start,start);
                        gap: 0.4vh;
                        flex-direction: column;
                        label{
                            color: var(--font-sub-font, #51525C);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px; 
                            text-align: start;
                        }
                        .value{
                            margin: 0;
                            color: var(--font-mine, #FCFCFC);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 16px;
                            max-width: 150px;
                            white-space: nowrap; // Запрещаем перенос текста на следующую строку
                            overflow: hidden; // Обрезаем текст, если он не помещается
                            text-overflow: ellipsis; 
                        }
                    }
                    .amount_label{
                        @include flex(end,end);
                        gap: 0.4vh;
                        flex-direction: column;
                        label{
                            color: var(--font-sub-font, #51525C);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px; 
                            text-align: start;

                        }
                        .amount{
                            @include flex(center,center);
                            margin: 0;
                            color: var(--Other-Green, #00B4A0);
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 16px;
                            letter-spacing: 0.24px;
                            gap: 0.4vh;
                        }
                    }
                }
                hr{
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid  var(--border-main);
                    margin: 0;
                }
                .name_adress {
                    display: flex;
                    width: 100%;
                    align-items: stretch;
                    justify-content: space-between;
                    .name,
                    .address {
                        flex: 0.5;
                        max-width: 150px;
                        white-space: nowrap; // Запрещаем перенос текста на следующую строку
                        overflow: hidden; // Обрезаем текст, если он не помещается
                        text-overflow: ellipsis; // Добавляем многоточие в конце строки
                    }
                
                    .name {
                        color: var(--font-mine, #FCFCFC);
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.24px;
                    }
                
                    .address {
                        text-align: right;
                        overflow: hidden;
                        color: var(--font-mine, #FCFCFC);
                        text-overflow: ellipsis;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px; /* 133.333% */
                        letter-spacing: 0.24px;
                    }
                }
                
            }
        }
        .withdrawal_form{
            margin-top: 4.6vh;
            margin-bottom: 4.6vh;
            flex: 0.47;
            padding: 2.4vh;
            @include flex(center,start);
            flex-direction: column;
            gap: 2.4vh;
            .withdrawal_type{
                width: 100%;
                @include flex(center,center);
                gap: 0.8vh;
                .type{
                    @include flex (center,center);
                    flex-direction: column;
                    flex: 1;
                    padding: 1.6vh;
                    gap: 1.6vh;
                    color: var(--font-mine);
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 0.28px;
                    border-radius: 1.6vh;
                    cursor: pointer;
                    border: 1px solid var(--border-main);
                    transition: all 0.15s ease-in-out;
                    user-select: none;
                    path{
                        stroke: var(--font-mine);
                    }
                    &.active{
                        border-radius: 16px;
                        border: 1px solid var(--other-purple);
                        background: rgba(168, 85, 247, 0.08);
                    }
                }
            }
            .amount{
                width: 100%;
                @include flex(start,start);
                flex-direction: column;
                gap: 1.2vh;
                position: relative;
                label{
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }
                input{
                    padding: 1.4vh 0.8vh 1.4vh 1.6vh;
                    border-radius: var(--corner-full, 1000px);
                    border: 1px solid var(--border-main);
                    background: var(--input-bg);
                    font-family: $font;
                    color: var(--font-mine);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    outline: none;
                    width: calc(100% - 2.4vh);
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }

                /* Firefox */
                input[type=number] {
                -moz-appearance: textfield;
                }
                .card_type{
                    position: absolute;
                    top: 22px;
                    right: -70px;
                    width: 50px;
                    height: 50px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .buttons{
                width: 100%;
                @include flex(center,center);
                gap: 0.8vh;
                .cancel{
                    border: none;
                    font-family: $font;
                    flex: 1;
                    padding: 1.6vh 1.2vh;
                    color: var(--nav-button-font-active);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    border-radius: 9999px;
                    text-decoration: none;
                    text-align: center;
                    background: var(--buttons-alternative-default);
                    transition: all 0.15s ease-in-out;
                }
                .cancel:hover{
                    background: var(--buttons-alternative-hover);
                }
                .submit{
                    flex: 1;
                    padding:  1.6vh 1.2vh;
                    color: var(--global-dark-25);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    border: none;
                    border-radius: 9999px;
                    font-family: $font;
                    background: var(--other-purple);
                    transition: all 0.15s ease-in-out;
                    cursor: pointer;
                }
                .submit:hover{
                    box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
                }
            }
        }
    }
}

@media (max-width: 600px){
    .withdrawal{
        width: 100%;
        border: none;
        background: none;
        .withdrawal_title{
            border: none;
            padding: 0;
            width: 100%;
            margin-top: 12px;
            a , span{
                font-size: 16px;
                line-height: 16px;
            }
            svg{
                width: 16px;
                height: 16px;
            }
        }
        .withdrawal_body{
            width: 100%;
            padding: 0;
            flex-direction: column;
            @include flex(center,center);
            .templates_body{
                align-items: center;
                justify-content: start;
                margin: 0;
                max-height: 500px;
                margin-top: 40px;

            }
            .withdrawal_form{
                padding: 0;
                flex: 0.9;
                width: 100%;
                .withdrawal_type{
                    margin-bottom: 12px;
                    .type{
                        text-align: center;
                        height: 100%;
                        
                    }
                }
                .amount{
                    label{
                        font-size: 12px;
                        line-height: 16px;
                    }
                    input{
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
                .buttons{
                    a , button{
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}
@import '../../styles/vars.scss';

@mixin for-mobile {
    @media screen and (max-width: 600px) {
      @content;
    }
  }

@mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1250px) {
      @content;
    }
  }

@mixin for-middle1 {
    @media screen and (min-width: 1100.1px) and (max-width: 1250px) {
      @content;
    }
  }

@mixin for-middle2 {
    @media screen and (min-width: 600.1px) and (max-width: 1100px) {
      @content;
    }
  }

.accountTypes {
    width: 100%;
    @include flex(center, start);
    flex-direction: column;

    .header {
        margin-top: 12px;
        width: 100%;
        @include flex(center, center);
        flex-direction: column;
        height: 380px;
        background: url('../../imgs/Frame\ 3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        border-radius: 32px;

        @include for-middle {
            background-size: cover;
            background-position: bottom;
        }

        @include for-mobile {
            padding: 16px;
            box-sizing: border-box;
        }

        h1 {
            color: var(--Global-Dark-25, #FCFCFC);
            font-size: 64px;
            font-weight: 500;
            line-height: normal;
            margin: 0;
            margin-bottom: 12px;
        }

        p {
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
            margin: 0;
            width: 400px;
            width: 100%;
        }
    }
    .accountTypes_mobile{
        display: none;
    }
    .accountTypes_block {
        margin-top: 120px;
        width: 100%;
        @include flex(center, center);

        .typesBody {
            @include flex(center, start);
            flex-direction: column;
            width: 100%;
            .types_header{
                width: 100%;
                height: 200px;
                @include flex(stretch,center);
                gap: 9px;
                .header_name{
                    flex: 1;
                    @include flex(start,end);
                    flex-direction: column;
                    gap: 8px;
                    padding-bottom: 0;
                    padding-left: 0;
                    padding-right: 24px;
                    p{
                        color: var(--Global-Dark-400, #A0A0AB);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        margin: 0;
                    }
                    h1{
                        margin: 0;
                        color: var(--Global-Dark-25, #FCFCFC);
                        font-size: 32px;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
                .type{
                    padding: 16px;
                    flex: 1;
                    border-radius: 32px;
                    background: var(--Background-2, #18181B);
                    @include flex(center,space-between);
                    flex-direction: column;
                    cursor: pointer;
                    &.active{
                        transition: all 0.3s ease-in-out;
                        background-color: #A855F7;
                        a{
                            background: #BF7BFF;
                        }
                        .center{
                            span{
                                color: #D1D1D6;
                            }

                        }
                    }
                    .name{
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        margin: 0;
                    }
                    .center{
                        @include flex(center,center);
                        flex-direction: column;
                        gap: 8px;
                        p{
                            color: #FFF;
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 32px;
                            margin: 0;
                        }
                        span{
                            color: var(--Global-Dark-400, #A0A0AB);
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px;
                        }
                    }
                    a{
                        display: flex;
                        padding: 12px 24px;
                        justify-content: center;
                        align-items: center;
                        gap: 12px;
                        align-self: stretch;
                        border-radius: 9999px;
                        background: var(--Background-3, #1D1D20);
                        color: var(--Nav_Button-Font_Active, #FCFCFC);
                        font-size: 14px;
                        text-decoration: none;
                        font-weight: 700;
                        line-height: 16px;
                    }
                }
            }
            .rows{
                margin-top: 12px;
                width: 100%;
                @include flex(center, start);
                flex-direction: column;
                .types_row{
                    width: 100%;
                    @include flex(stretch,center);
                    gap: 9px;
                    border-bottom: 1px solid var(--Border-Main, #26272B);
                    .row_name{
                        flex: 1;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        color: var(--Global-Dark-400, #A0A0AB);
                        text-align: start;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16px;
                        margin: 0;
                    }
                    .value{
                        padding-top: 12px;
                        padding-bottom: 12px;
                        @include flex(center,center);
                        flex: 1;
                        p{
                            color: var(--Global-Dark-25, #FCFCFC);
                            text-align: center;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 16px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px){
    .accountTypes{
        .header{
            text-align: center;
            background-size: cover;
            background-position: bottom;
            height: 300px;
            h1{
                color: var(--Global-Dark-25, #FCFCFC);
                font-size: 48px;
                font-weight: 500;
                line-height: normal;
            }

        }
        .accountTypes_mobile{
            @include flex(center,start);
            flex-direction: column;
            padding: 16px;
            border-radius: 32px;
            background: var(--Background-2, #18181B);
            margin-top: 48px;
            width: calc(100% - 64px);
            .top{
                width: 100%;
                @include flex(center,start);
                gap: 32px;
                flex-direction: column;
                .name_buttons{
                    width: 100%;
                    @include flex(center,space-between);
                    p{
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                    }
                    .prev_next{
                        @include flex(center,center);
                        padding: 16px;
                        border-radius: 9999px;
                        background: var(--Background-3, #1D1D20);
                    }
                }
                .center{
                    @include flex(center,center);
                    flex-direction: column;
                    gap: 8px;
                    p{
                        margin: 0;
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 32px;
                    }
                    span{
                        color: var(--Global-Dark-400, #A0A0AB);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
                a{
                    display: flex;
                    padding: 12px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    align-self: stretch;
                    color: var(--Nav_Button-Font_Active, #FCFCFC);
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    border-radius: 9999px;
                    background: var(--Background-3, #1D1D20);
                    text-decoration: none;
                }
            }
            .rows{
                width: 100%;
                @include flex(center,start);
                flex-direction: column;
                .row{
                    width: 100%;
                    @include flex(stretch,center);
                    border-bottom: 1px solid var(--Border-Main, #26272B);

                    p{
                        text-align: start;
                        flex: 1;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        color: var(--Global-Dark-400, #A0A0AB);
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                    }
                    .value{
                        @include flex(center,center);
                        flex: 1;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        color: var(--Global-Dark-25, #FCFCFC);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 16px;
                    }
                }
                .row:last-child{
                    border: none;
                }
            }
        }
        .accountTypes_block{
            display: none;
        }
    }
}

@media (min-width: 600.1px) and (max-width: 1100px){
    .accountTypes{
        .header{
            text-align: center;
            background-size: cover;
            background-position: bottom;
            height: 300px;
            h1{
                color: var(--Global-Dark-25, #FCFCFC);
                font-size: 48px;
                font-weight: 500;
                line-height: normal;
            }
            p{
                width: 329px;
            }

        }
        .accountTypes_mobile{
            @include flex(center,start);
            flex-direction: column;
            padding: 16px;
            border-radius: 32px;
            background: var(--Background-2, #18181B);
            margin-top: 48px;
            width: calc(100% - 64px);
            .top{
                width: 100%;
                @include flex(center,start);
                gap: 32px;
                flex-direction: column;
                .name_buttons{
                    width: 100%;
                    @include flex(center,space-between);
                    p{
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                    }
                    .prev_next{
                        @include flex(center,center);
                        padding: 16px;
                        border-radius: 9999px;
                        background: var(--Background-3, #1D1D20);
                    }
                }
                .center{
                    @include flex(center,center);
                    flex-direction: column;
                    gap: 8px;
                    p{
                        margin: 0;
                        color: #FFF;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 32px;
                    }
                    span{
                        color: var(--Global-Dark-400, #A0A0AB);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
                a{
                    display: flex;
                    padding: 12px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    align-self: stretch;
                    color: var(--Nav_Button-Font_Active, #FCFCFC);
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    border-radius: 9999px;
                    background: var(--Background-3, #1D1D20);
                    text-decoration: none;
                }
            }
            .rows{
                width: 100%;
                @include flex(center,start);
                flex-direction: column;
                .row{
                    width: 100%;
                    @include flex(stretch,center);
                    border-bottom: 1px solid var(--Border-Main, #26272B);
                    p{
                        text-align: start;
                        flex: 1;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        color: var(--Global-Dark-400, #A0A0AB);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16px;
                    }
                    .value{
                        @include flex(center,center);
                        flex: 1;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        color: var(--Global-Dark-25, #FCFCFC);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 16px;
                    }
                }
                .row:last-child{
                    border: none;
                }
            }
        }
        .accountTypes_block{
            display: none;
        }
    }
}
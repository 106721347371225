@import '../../styles/vars.scss';
@import '../../styles/theme.scss';

@mixin for-mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}
@mixin for-middle {
  @media screen and (min-width: 600.1px) and (max-width: 1200px) {
    @content;
  }
}

.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include for-mobile {
    margin-left: 16px;
    margin-right: 16px;
    max-width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.header {
    margin-top: 12px;
    width: 100%;
    @include flex(center, center);
    flex-direction: column;
    height: 380px;
    background: url('../../imgs/Frame 3.png');
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 32px;
  
    @include for-mobile {
      background-size: cover;
      background-position: bottom;
    }

    @include for-middle {
      background-size: cover;
      background-position: bottom;
    }
  
    h1 {
      color: var(--Global-Dark-25, #FCFCFC);
      font-size: 64px;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      margin-bottom: 12px;
  
      @include for-mobile {
          width: 80%;
          text-align: center;
          font-size: 40px;
          font-style: normal;
      }
    }
  
    p {
      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      width: 900px;
  
      @include for-mobile {
          width: 80%;
      }

      @include for-middle {
          width: 80%;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 20px;

    @include for-mobile {
        width: 100%;
    }
  }

  .info__container {
    width: 100%;
    margin-top: 80px;

    h1 {
        color: var(--Neutrals-White, #FFF);
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @include for-mobile {
            font-size: 32px;
        }
    }

    p {
        color: var(--Font-Mine, #FCFCFC);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @include for-mobile {
            font-size: 14px;
        }
    }
  }

  .info__container_frame {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 9999px;
    color: var(--Neutrals-White, #FFF);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 6%;
    justify-content: center;
    margin: auto;
    border: 1px solid transparent;
    background: 
      linear-gradient(to right, #18181B, #18181B), 
      linear-gradient(to right, #A855F78F , #FFFFFF8F); 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    @include flex(center,center);
    font-family: $font;

    @include for-mobile {
        width: 25%;
    }

    @include for-middle {
        width: 15%;
    }
  }
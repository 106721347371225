@import './theme.scss';
@import './vars.scss';

.navbar{
    padding: 1.2vh;
    width: 21.4vh;
    border-radius: 32px;
    border: 1px solid var(--border-main);
    background: var(--background-2);
    .navigation{
        @include flex(center,start);
        flex-direction: column;
        .to_terminal{
            width: calc(100% - 2.4vh);
            @include flex(center,center);
            gap: 1.2vh;
            border-radius: 9999px;
            background: var(--other-purple);
            padding: 1.6vh 1.2vh;   
            color: var(--global-dark-25);
            font-size: 1.4vh;
            font-weight: 500;
            line-height: 1.6vh; 
            cursor: pointer;
            text-decoration: none;
            transition: all 0.15s ease-in-out;
            border: none;
            font-family: "Inter",sans-serif;
        }
        .to_terminal:hover{
            box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
        }
        .nav_links{
            margin-top: 2.4vh;
            width: 100%;
            @include flex(center,start);
            flex-direction: column;
            gap: 0.8vh;

            .menu{
                margin-bottom: 0.8vh;
                width: 100%;
                text-align: left;
                color: var(--font-sub-font);
                font-size: 1.4vh;
                font-style: normal;
                font-weight: 500;
                line-height: 1.6vh;
            }
            .button{
                width: calc(100% - 2.4vh);
                @include flex(center,start);
                gap: 1.2vh;
                padding: 1.2vh;
                color: var(--nav-button-font-default);
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                text-decoration: none;
                transition: all 0.15s ease-in-out;
                path{
                    fill: var(--nav-button-font-default);
                }
                ellipse{
                    fill: var(--nav-button-font-default);
                }
                &.active{
                    border-radius: 9999px;
                    background: var(--nav-button-active);
                    color: var(--nav-button-font-active);
                    path{
                        fill: var(--nav-button-font-active);
                    }
                    ellipse{
                        fill: var(--nav-button-font-active);
                    }
                }
            }
            .button:hover{
                border-radius: 9999px;
                background: var(--nav-button-hover);
            }
        }
    }
}
@media (max-width: 600px) {

            .navbar{
                display: none;
            }
    
}
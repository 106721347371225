@import './theme.scss';
@import './vars.scss';

.layout{
    padding: 24px 32px;
    width: calc(100% - 64px);
    @include flex(center,start);
    flex-direction: column;
    background: #131316;
}

@media (max-width: 600px) {
    .layout{
        padding: 0;
        width: 100%;
    }
}

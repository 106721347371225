@import '../../styles/varsLanding.scss';
@import '../../styles/theme.scss';
@import '../../styles/vars.scss';

@mixin for-mobile {
    @media screen and (max-width: 600px) {
      @content;
    }
  }

@mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1200px) {
      @content;
    }
  }

.MainPage {
    @include flex(center, start);
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    background: #131316;

    .header {
        width: 100%;
        @include flex(center, center);
        flex-direction: column;
        height: 774px;
        background: url('../../imgs/Frame\ 2.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 32px;
        margin-top: 16px;

        @include for-mobile {
            padding: 20px;
            box-sizing: border-box;
        }
        .Terminal{
            display: flex;
            padding: 4px 8px;
            align-items: center;
            gap: 4px;
            border-radius: 9999px;
            color: var(--Neutrals-White, #FFF);
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            text-decoration: none;
            border: 1px solid var(--Linear, #A855F7);
            background: rgba(160, 160, 171, 0.08);  
        }
        h1 {
            color: var(--Global-Dark-25, #FCFCFC);
            text-align: center;
            font-size: 64px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 0;
            margin-top: 32px;

            @include for-mobile {
                width: 100%;
                font-size: 42px !important;
            }
        }

        p {
            color: var(--Neutrals-White, #FFF);
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
        }

        .Cabinet {
            margin-top: 64px;
            z-index: 3;
            @include flex(center, center);
            gap: 12px;
            width: 188px;
            height: 24px;
            padding: 12px;
            color: var(--Nav_Button-Font_Active, #FCFCFC);
            font-family: $fontLanding;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            cursor: pointer;
            text-decoration: none;
            border-radius: 9999px;
            border: 2px solid rgba(255, 255, 255, 0.12);
            background: var(--Other-Purple, #A855F7);
            box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
        }

        .Cabinet:hover {
            transition: all 0.3s ease;
            box-shadow: 0px 0px 20px -1px rgba(82, 165, 230, 0.62);
            color: #fff;
        }
    }

    .section1 {
        margin-top: 100px;
        width: 100%;
        @include flex(center, center);

        .section1Body {
            width: 90%;
            @include flex(center, start);
            flex-direction: column;
            gap: 86px;
            .top_block{
                @include flex(center, start);
                flex-direction: column;
                div{
                    border: 1px solid transparent;
                    border-radius: 1000px;
                    background: 
                      linear-gradient(to right, #18181B, #18181B), 
                      linear-gradient(to right, #A855F78F , #FFFFFF8F); 
                    background-clip: padding-box, border-box;
                    background-origin: padding-box, border-box;
                    @include flex(center,center);
                    padding: 4px 8px;
                    color: var(--Neutrals-White, #FFF);
                    font-family: $font;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                }
                h1{
                    color: var(--Neutrals-White, #FFF);
                    text-align: center;
                    font-size: 48px;
                    font-weight: 500;
                    line-height: normal;
                    margin-top: 32px;
                    margin-bottom: 12px;
                }
                p{
                    color: var(--Font-Sub-Font, #51525C);
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                }
            }
            .bottom_block{
                @include flex(stretch,center);
                gap: 32px;
                .block{
                    flex: 1;
                    padding: 32px;
                    @include flex(start,start);
                    flex-direction: column;
                    border-radius: 32px;
                    background: var(--Background-2, #18181B);
                    .top{
                        @include flex(center,start);
                        gap: 16px;
                        color: var(--Neutrals-White, #FFF);
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 32px;
                    }
                    .text{
                        color: var(--Global-Dark-300, #D1D1D6);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

        }
    }

    .section2 {
        margin-top: 132px;
        width: 100%;
        @include flex(center, center);

        .section2Body {
            width: 90%;
            @include flex(center, start);
            flex-direction: column;
            gap: 86px;
            .top_block{
                @include flex(center, start);
                flex-direction: column;
                div{
                    border: 1px solid transparent;
                    border-radius: 1000px;
                    background: 
                      linear-gradient(to right, #18181B, #18181B), 
                      linear-gradient(to right, #A855F78F , #FFFFFF8F); 
                    background-clip: padding-box, border-box;
                    background-origin: padding-box, border-box;
                    @include flex(center,center);
                    padding: 4px 8px;
                    color: var(--Neutrals-White, #FFF);
                    font-family: $font;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                }
                h1{
                    color: var(--Neutrals-White, #FFF);
                    text-align: center;
                    font-size: 48px;
                    font-weight: 500;
                    line-height: normal;
                    margin-top: 32px;
                    margin-bottom: 12px;

                    @include for-mobile {
                        font-size: 32px;
                    }
                }
                p{
                    color: var(--Font-Sub-Font, #51525C);
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                }
            }
            .blocks{
                width: 100%;
                @include flex(start,start);
                gap: 86px;
                flex-direction: column;
                .block{
                    height: 600px;
                    width: 100%;
                    @include flex(stretch,center);
                    gap: 32px;
                    img{
                        width: 600px;
                        height: 600px;

                        @include for-mobile {
                            width: 268px !important;
                        height: 268px !important;
                        }
                    }
                    .block_content{
                        padding: 32px;
                        width: 536px;
                        height: 536px;
                        @include flex(start,center);
                        flex-direction: column;
                        gap: 32px;
                        span{
                            color: var(--Other-Purple, #A855F7);
                            font-size: 12px;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: uppercase;
                        }
                        h3{
                            color: var(--Neutrals-White, #FFF);
                            font-size: 32px;
                            font-weight: 500;
                            line-height: normal;
                            margin: 0;
                        }
                        p{
                            color: var(--Global-Dark-300, #D1D1D6);
                            font-size: 18px;
                            font-weight: 400;
                            line-height: normal;
                            white-space: pre-line;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    .section3{
        margin-top: 212px;
        width: 100%;
        @include flex(center,center);
        .section3Body{
            width: 90%;
            @include flex(start,start);
            flex-direction: column;
            gap: 32px;
            min-height: 517px;
            .how{
                border: 1px solid transparent;
                    border-radius: 1000px;
                    background: 
                      linear-gradient(to right, #18181B, #18181B), 
                      linear-gradient(to right, #A855F78F , #FFFFFF8F); 
                    background-clip: padding-box, border-box;
                    background-origin: padding-box, border-box;
                    @include flex(center,center);
                    padding: 4px 8px;
                    color: var(--Neutrals-White, #FFF);
                    font-family: $font;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
            }
            .block_content{
                @include flex(start,start);
                gap: 48px;
                .left_side{
                    @include flex(start,start);
                    flex-direction: column;
                    gap: 12px;
                    width: 40%;
                    h1{
                        color: var(--Neutrals-White, #FFF);
                        font-size: 48px;
                        font-weight: 500;
                        line-height: normal;
                        margin: 0;
                    }
                    p{
                        color: var(--Font-Sub-Font, #51525C);
                        font-size: 18px;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                    }
                }
                .right_side{
                    width: 60%;
                    @include flex(center,start);
                    flex-direction: column;
                    gap: 16px;
                    .accordion_head{
                        @include flex(center,start);
                        gap: 16px;
                        span{
                            color: var(--Neutrals-White, #FFF);
                            font-size: 24px;
                            font-weight: 300;
                            line-height: normal;
                        }
                        h2{
                            color: var(--Neutrals-White, #FFF);
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 32px;
                            margin: 0;
                        }
                    }
                    .accordion_text{
                        color: var(--Global-Dark-300, #D1D1D6);
                        font-size: 18px;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                    }
                }
            }
        }
    }
    .section4{
        margin-top: 180px;
        width: 100%;
        @include flex(center,center);
        .section4Body{
            width: 90%;
            @include flex(start,space-between);
            gap: 75px;
            border-radius: 32px;
            background: var(--Other-Purple, #A855F7);
            height: 560px;
            .left_side{
                max-width: 449px;
                padding-top: 72px;
                padding-left: 72px;
                h1{
                    margin: 0;
                    color: var(--Neutrals-White, #FFF);
                    font-size: 56px;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
    .section5{
        margin-top: 180px;
        width: 100%;
        @include flex(center,center);
        .section5Body{
            width: 65%;
            @include flex(center, start);
            flex-direction: column;
            gap: 86px;
            .top{
                @include flex(center, start);
                flex-direction: column;
                .why{
                    border: 1px solid transparent;
                    border-radius: 1000px;
                    background: 
                      linear-gradient(to right, #18181B, #18181B), 
                      linear-gradient(to right, #A855F78F , #FFFFFF8F); 
                    background-clip: padding-box, border-box;
                    background-origin: padding-box, border-box;
                    @include flex(center,center);
                    padding: 4px 8px;
                    color: var(--Neutrals-White, #FFF);
                    font-family: $font;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                }
                h1{
                    color: var(--Neutrals-White, #FFF);
                    font-size: 48px;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0;
                    text-align: center;
                    margin-top: 32px;
                }
                p{
                    color: var(--Font-Sub-Font, #51525C);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                    margin-top: 12px;
                }
            }
            .bottom{
                width: 100%;
                height: 579px;
                @include flex(stretch,stretch);
                gap: 8px;
                .left{
                    flex: 1;
                    width: 100%;
                    background: url('../../imgs/section5Bg.svg');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    @include flex(center,end);
                    flex-direction: column;
                    gap: 32px;
                    .why{
                        border: 1px solid transparent;
                        border-radius: 1000px;
                        background: 
                          linear-gradient(to right, #18181B, #18181B), 
                          linear-gradient(to right, #A855F78F , #FFFFFF8F); 
                        background-clip: padding-box, border-box;
                        background-origin: padding-box, border-box;
                        @include flex(center,center);
                        padding: 4px 8px;
                        color: var(--Neutrals-White, #FFF);
                        font-family: $font;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: normal;

                        @include for-mobile {
                                margin-top: 270px;
                        }
                    }
                    h1{
                        color: var(--Neutrals-White, #FFF);
                        text-align: center;
                        font-size: 32px;
                        font-weight: 500;
                        line-height: normal;
                        margin-top: 0;
                        margin-bottom: 60px;

                        @include for-middle {
                            margin-top: 150px;
                        }
                    }
                }
                .right {
                    flex: 1;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: stretch;
                    gap: 8px;
                    .block_content {
                        flex-basis: calc(50% - 68px);
                        padding: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        gap: 32px;
                        border-radius: 32px;
                        background: var(--Background-2, #18181B);
                        min-height: 200px;

                        @include for-mobile {
                            flex: 1 1 calc(50% - 8px);
                            padding: 16px;
                            box-sizing: border-box;
                        }
                
                        h1 {
                            color: var(--Neutrals-White, #FFF);
                            font-size: 56px;
                            font-weight: 700;
                            line-height: 56px;
                            margin: 0;

                            @include for-mobile {
                                font-size: 32px;
                            }
                        }
                
                        p {
                            color: var(--Global-Dark-25, #FCFCFC);
                            text-align: center;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: normal;
                            margin: 0;

                            @include for-mobile {
                                font-size: 14px !important;
                            }
                        }
                    }
                }
                
                
            }
        }
    }
}

@media (max-width: 1200px){
    .MainPage {
        .header {
            margin-top: 77px;
            height: 564px;
            justify-content: start;
            h1{
                font-size: 48px;
            }
            p{
                width: 90%;
                font-size: 18px;
            }
        }
        .section1{
            .section1Body{
                gap: 46px;
                .top_block{
                    h1{
                        font-size: 32px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
                .bottom_block{
                    flex-wrap: wrap;
                    .block{
                        .top{
                            font-size: 16px;
                        }
                        .text{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .section2{
            height: 100%;
            .section2Body{
                .blocks{
                    gap: 48px;
                    .block{
                        flex-wrap: wrap;
                        height: 100%;
                        
                        img{
                            width: 361px;
                            height: 361px;
                            align-self: center;
                        }
                        .block_content{
                            padding: 32px;
                            justify-content: start;
                            height: 100%;
                            width: calc(100% - 64px);
                            align-items: center;
                            span {
                                text-align: center;
                            }
                            h3{
                                font-size: 24px;
                                text-align: center !important;
                                align-self: center;
                                text-wrap: wrap;
                            }
                            p{
                                font-size: 14px;
                                width: 100%;
                                word-wrap: normal;
                                text-align: center;
                            }
                        }
                    }
                    .block:nth-child(2){
                        flex-direction: column-reverse;
                    }
                }
            }
        }
        .section3{
            margin-top: 116px;
            .section3Body{
                width: 90%;
                padding: 0;
                align-items: center;
                .block_content{
                    width: 100%;
                    justify-content: center;
                    flex-wrap: wrap;
                    .left_side{
                        width: 100%;
                        align-items: center;
                        h1{
                            text-align: center;
                            font-size: 32px;
                        }
                        p{
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                    .right_side{
                        .accordion_text{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .section4{
            margin-top: 84px;
            .section4Body{
                width: 90%;
                flex-wrap: wrap;
                height: 100%;
                padding-bottom: 32px;
                justify-content: center;
                .left_side{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    padding-top: 32px;
                    h1{
                        width: 90%;
                        font-size: 32px;
                        text-align: center;
                    }
                }
            }
        }
        .section5{
            margin-top: 84px;
            .section5Body{
                width: 90%;
                gap: 16px;
                .top{
                    h1{
                        font-size: 32px;
                    }
                    p{
                        text-align: center;
                        font-size: 14px;
                    }
                }
                .bottom{
                    height: 100%;
                    @include flex(center,start);
                    flex-direction: column;
                    height: 100%;
                    .left{
                        @include flex(center,center);
                        width: 100%;
                        background-size: 100% ;
                        min-height: 380px !important;
                        max-width: 580px;
                        border-radius: 32px;
                        h1{
                            margin-bottom: 0;
                            font-size: 24px;
                            font-weight: 500;
                            width: 60%;
                            padding-bottom: 20px;
                        }
                    }
                    .right{
                        max-height: 360px;
                        .block_content{
                            min-height: 100%;
                            p{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@import '../../styles/vars.scss';

@mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1200px) {
      @content;
    }
  }

@mixin for-mobile {
    @media screen and (max-width: 600px) {
      @content;
    }
  }

.tools_page{
    width: 100%;
    @include flex(center,start);
    flex-direction: column;
    .header {
        margin-top: 12px;
        width: 100%;
        @include flex(center, center);
        flex-direction: column;
        height: 380px;
        background: url('../../imgs/Frame\ 3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        border-radius: 32px;

        @include for-middle {
            background-size: cover;
            background-position: bottom;
        }
        
        @include for-mobile {
            justify-content: center;
        }

        h1 {
            color: var(--Global-Dark-25, #FCFCFC);
            font-size: 64px;
            font-weight: 500;
            line-height: normal;
            margin: 0;
            margin-bottom: 12px;
            
            @include for-mobile {
                width: 80%;
                font-size: 40px;
                text-align: center;
            }
        }

        p {
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
            margin: 0;
            width: 400px;

            @include for-mobile {
                width: 80%;
            }
        }
    }
    .body{
        margin-top: 120px;
        width: 73%;
        @include flex(center,start);
        flex-direction: column;
        gap: 12px;

        @include for-middle {

        }
        .tools_list{
            width: 100%;
            height: 148px;
            @include flex(stretch,space-between);
            gap: 12px;

            @include for-middle {
                width: 100%;
                justify-content: start;
                max-width: 90vw;
                overflow-x: auto;
                height: 100%;
                gap: 8px;
            }

            .tool{
                padding: 24px;
                flex: 1;
                @include flex(start,space-between);
                flex-direction: column;
                cursor: pointer;
                &.active{
                    border-radius: 32px;
                    border: 1px solid var(--Other-Purple, #A855F7);
                    background: var(--Background-2, #18181B);
                }

                h2{
                    width: 100%;
                    color: var(--Global-Dark-400, #A0A0AB);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    margin: 0;
                }
            }
        }
        .widget{
            width: 100%;
            min-height: 638px;
        }
    }
}
@media (max-width: 600px){
    .tools_page{
        .header{
            background-size: cover;
            background-position: bottom;
        }
        .body{
            width: 90%;
            .tools_list{
                width: 100%;
                justify-content: start;
                max-width: 90vw;
                overflow-x: auto;
                height: 100%;
                gap: 8px;
                .tool{
                    padding: 16px;
                    min-width: 113px;
                    flex-direction: row;
                    align-items: start;
                    justify-content: start;
                    gap: 12px;
                    border-radius: 16px;
                    &.active{
                        border-radius: 16px;
                    }
                    h2{
                        text-decoration: none;
                        font-size: 12px;
                    }
                }
            }
        }

    }
}
@import '../../styles/vars.scss';
@import '../../styles/theme.scss';

.withdrawal{
    flex: 12;
    border-radius: 32px;
    border: 1px solid var(--border-main);
    background: var(--background-2);
    @include flex(center,start);
    flex-direction: column;
    .withdrawal_title{
        border-bottom: 1px solid var(--border-main);
        padding: 2.4vh;
        width: calc(100% - 4.8vh);
        @include flex(center,start);
        gap: 0.8vh;
        color: var(--nav-button-font-active);
        font-size: 1.6vh;
        font-weight: 500;
        line-height: 1.6vh;
        a{
            @include flex(center,start);
            gap: 0.8vh;
            color: var(--font-sub-font);
            font-size: 1.6vh;
            font-weight: 500;
            line-height: 1.6vh; 
            text-decoration: none;
            path{
                fill: var(--font-sub-font);
            }
        }
    }
    .withdrawal_body{
        width: calc(100% - 2.4vh);
        padding: 2.4vh;
        height: 100%;
        @include flex(stretch,center);
        .withdrawal_form{
            margin-top: 4.6vh;
            margin-bottom: 4.6vh;
            flex: 0.37;
            padding: 2.4vh;
            @include flex(center,start);
            flex-direction: column;
            gap: 2.4vh;
            .withdrawal_type{
                width: 100%;
                @include flex(center,center);
                gap: 0.8vh;
                .type{
                    @include flex (center,center);
                    flex-direction: column;
                    flex: 1;
                    padding: 1.6vh;
                    gap: 1.6vh;
                    color: var(--font-mine);
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 0.28px;
                    border-radius: 1.6vh;
                    cursor: pointer;
                    border: 1px solid var(--border-main);
                    transition: all 0.15s ease-in-out;
                    user-select: none;
                    path{
                        stroke: var(--font-mine);
                    }
                    &.active{
                        border-radius: 16px;
                        border: 1px solid var(--other-purple);
                        background: rgba(168, 85, 247, 0.08);
                    }
                }
            }
            .amount{
                width: 100%;
                @include flex(start,start);
                flex-direction: column;
                gap: 1.2vh;
                label{
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }
                input{
                    padding: 1.4vh 0.8vh 1.4vh 1.6vh;
                    border-radius: var(--corner-full, 1000px);
                    border: 1px solid var(--border-main);
                    background: var(--input-bg);
                    font-family: $font;
                    color: var(--font-mine);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    outline: none;
                    width: calc(100% - 2.4vh);
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }

                /* Firefox */
                input[type=number] {
                -moz-appearance: textfield;
                }
            }
            .buttons{
                width: 100%;
                @include flex(center,center);
                gap: 0.8vh;
                a{
                    flex: 1;
                    padding: 1.6vh 1.2vh;
                    color: var(--nav-button-font-active);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    border-radius: 9999px;
                    text-decoration: none;
                    text-align: center;
                    background: var(--buttons-alternative-default);
                    transition: all 0.15s ease-in-out;
                }
                a:hover{
                    background: var(--buttons-alternative-hover);
                }
                button{
                    flex: 1;
                    padding:  1.6vh 1.2vh;
                    color: var(--global-dark-25);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    border: none;
                    border-radius: 9999px;
                    font-family: $font;
                    background: var(--other-purple);
                    transition: all 0.15s ease-in-out;
                    cursor: pointer;
                }
                button:hover{
                    box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
                }
            }
        }
    }
}

@media (max-width: 600px){
    .withdrawal{
        width: 100%;
        border: none;
        background: none;
        .withdrawal_title{
            border: none;
            padding: 0;
            width: 100%;
            margin-top: 12px;
            a , span{
                font-size: 16px;
                line-height: 16px;
            }
            svg{
                width: 16px;
                height: 16px;
            }
        }
        .withdrawal_body{
            width: 100%;
            padding: 0;
            .withdrawal_form{
                padding: 0;
                flex: 0.9;
                width: 100%;
                .withdrawal_type{
                    margin-bottom: 12px;
                    .type{
                        text-align: center;
                        height: 100%;
                        
                    }
                }
                .amount{
                    label{
                        font-size: 12px;
                        line-height: 16px;
                    }
                    input{
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
                .buttons{
                    a , button{
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}
@import './vars.scss';
.carouselWrapper{
    @include flex(center,end);
    gap: 32px;
    padding-right: 32px;
    .pagination {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-top: 20px;
    }
    
    .paginationBullet {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.24);
        cursor: pointer;
        transition: background-color 0.3s;
    }
    
    .paginationBullet.active {
        background-color: #FFF;
    }
}
.body{
    height:100%;
    max-height: 560px;
    overflow: hidden;
    margin: 0 !important;
    .slide{
      display:flex;
      align-items:center;
      flex-direction:column;
      justify-content:start;
      height:100%;
      .slide_content{
        padding: 40px;
        width:432px;
        height:280px;
        display:flex;
        align-items:start;
        justify-content:space-between;
        flex-direction:column;
        border-radius: 32px;
        background: var(--Neutrals-White, #FFF);
        gap:10px;
        overflow:hidden;
        .top{
            @include flex(start,start);
            flex-direction: column;
            gap: 24px;
            .text{
              margin: 0;
              font-weight: 600;
              font-size: 30px;
              line-height: 35px;
              color: var(--Global-Dark-800, #26272B);
              font-size: 16px;
              font-weight: 500;
              line-height: normal;
            }

        }
        .name{
            color: var(--Global-Dark-800, #26272B);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
        }
      }
    }
  }
  @media (max-width: 1200px){
    .carouselWrapper{
        display: none;
    }
  }
@import '../../styles/vars.scss';
@import '../../styles/theme.scss';

@mixin for-mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin for-middle {
  @media screen and (min-width: 600.1px) and (max-width: 1200px) {
    @content;
  }
}

@mixin for-middle1 {
  @media screen and (min-width: 600.1px) and (max-width: 1000px) {
    @content;
  }
}

.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include for-mobile {
    margin-left: 16px;
    margin-right: 16px;
    max-width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
  }
}

.trade_info {
  margin: 96px 32px 120px 32px;
  background: url('../../imgs/Frame 2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
}

.header {
  margin-top: 12px;
  width: 100%;
  @include flex(center, center);
  flex-direction: column;
  height: 380px;
  background: url('../../imgs/Frame 3.png');
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 32px;

  @include for-mobile {
    background-size: cover;
    background-position: bottom;
    height: 100%;
    padding: 20px 0;
  }
  
  @include for-middle {
    background-size: cover;
    background-position: bottom;
}

  h1 {
    color: var(--Global-Dark-25, #FCFCFC);
    font-size: 64px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-bottom: 12px;

    @include for-mobile {
        width: 80%;
        color: var(--Global-Dark-25, #FCFCFC);
        text-align: center;
        font-size: 40px;
        font-style: normal;
    }

    @include for-middle {
        width: 80%;
        text-align: center;
    }
  }

  p {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    width: 900px;

    @include for-mobile {
        width: 80%;
    }

    @include for-middle {
      width: 80%;
      text-align: center;
  }
  }
}

.info_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-mobile {
    margin-left: 16px;
    margin-right: 16px;
    max-width: calc(100% - 32px);
    justify-content: start;
  }
}

.info {
  color: var(--Global-Dark-25, #FCFCFC);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  @include for-mobile {
    max-width: 100%;
  }

  h1 {
    font-size: 50px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-bottom: 35px;
    margin-top: 80px;
    text-align: center;
    width: 1100px;

    @include for-mobile {
      color: #FFF;
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      overflow-wrap: break-word;
      width: 100%;
    }

    @include for-middle {
      width: 100%;
    }
  }

  p {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    width: 800px;
    margin-bottom: 35px;

    @include for-mobile {
      color: #FFF;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
    }
    
    @include for-middle {
      width: 100%;
    }
  }

  span {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    width: 800px;
    color: #ffffff54;

    @include for-mobile {
      width: 100%;
      color: var(--Font-Sub-Font, #51525C);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
    
    @include for-middle {
      width: 100%;
    }
  }
}

.blocks {
  width: 100%;
  display: flex;
  margin-top: 40px;
  align-items: stretch;
  justify-content: space-between;
  gap: 32px;
  flex: 1 0 0;
  
  @include for-mobile {
    flex-direction: column;
  }

  @include for-middle1 {
    flex-direction: column;
  }

  .block {
    flex: 1;
    border-radius: 32px;
    background: var(--Background-2, #18181B);
    color: #FCFCFC;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include for-mobile {
      width: calc(100% - 64px);
    }
  }
}

.block:last-child {
  justify-content: space-between;
}

.button {
  display: flex;
  height: 24px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 9999px;
  background: var(--Other-Purple, #A855F7);
  width: 301px;
  color: var(--Nav_Button-Font_Active, #FCFCFC);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin-left: 2.5%;
  width: 90%;
  text-decoration: none;

}

.button:hover {
  box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
  transition: 0.3s;
}

.blocks__background {
  display: flex;
  align-self: stretch;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--Background-3, #1D1D20);
  margin-top: 16px;
  margin-bottom: 32px;
  height: 54px;
}

.blocks__orange_info {
  color: var(--Other-Orange, #E9770E);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
}

.blocks__green_info {
  color: var(--Other-Green, #00B4A0);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
}

.blocks__blue_info {
  color: var(--Other-Brand-main, #52A5E5);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
}

.title {
  color: #FFF;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 64px;
}

.list {
  color: var(--Font-Sub-Font, #51525C);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 0px;
  padding-left: 0px;
  align-self: flex-start;
  margin-left: 20px;

  li {
    color: var(--Font-Mine, #FCFCFC);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    list-style: none;
    margin-bottom: 12px;
    display: flex;
  }

  li:first-child {
    margin-top: 16px;
  }
}

.custom_checkbox {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  user-select: none;
}

.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom_checkbox .checkmark {
  position: absolute;
  left: 0;
  top: 0;
  height: 16px;
  width: 16px;
  background-color: var(--Background-2, #18181B);
  border-radius: 50%;
  border: 1px solid #cccccc38;
  transition: background-color 0.3s ease;
}

.custom_checkbox:hover .checkmark {
  background-color: var(--Background-2, #18181B);
}

.custom_checkbox input:checked ~ .checkmark {
  background-color: var(--Background-2, #18181B);
  border-color: #A855F7;
}

.custom_checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #A855F7;
}

.custom_checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include for-mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.last_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
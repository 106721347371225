@import '../../styles/vars.scss';

@mixin for-middle {
    @media screen and (min-width: 600.1px) and (max-width: 1200px) {
        @content;
    }
}

@mixin for-desktop {
    @media screen and (min-width: 900.1px) {
        @content;
    }
}

@mixin for-mobile {
    @media screen and (max-width: 600px) {
        @content;
    }
}

.affprogram_page {
    width: 100%;
    @include flex(center, start);
    flex-direction: column;

    @include for-mobile {
        overflow-x: hidden;
    }

    .header {
        margin-top: 12px;
        width: 100%;
        @include flex(center, center);
        flex-direction: column;
        height: 380px;
        background: url('../../imgs/Frame\ 3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        border-radius: 32px;

        @include for-middle {
            background-size: cover;
            background-position: bottom;
        }

        @include for-mobile {
            padding: 20px;
            box-sizing: border-box;
        }

        h1 {
            color: var(--Global-Dark-25, #FCFCFC);
            font-size: 64px;
            font-weight: 500;
            line-height: normal;
            margin: 0;
            margin-bottom: 12px;
            text-align: center;
        }

        p {
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
            margin: 0;
            width: 45vw;
        }
    }

    .section1 {
        margin-top: 120px;
        width: 100%;
        @include flex(center, center);

        .section1Body {
            @include flex(center, start);
            flex-direction: column;
            gap: 86px;
            width: 73%;

            @include for-mobile {
                justify-content: center;
                align-items: center;
            }

            .top {
                width: 100%;
                @include flex(center, start);
                flex-direction: column;
                gap: 24px;


                h1 {
                    color: var(--Neutrals-White, #FFF);
                    text-align: center;
                    font-size: 48px;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0;
                }

                p {
                    color: var(--Font-Sub-Font, #51525C);
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                }
            }

            .bottom {
                width: 100%;
                @include flex(center, start);
                flex-direction: column;
                gap: 48px;

                .blocks {
                    width: 100%;
                    @include flex(start, start);
                    flex-direction: column;
                    gap: 32px;

                    .top_blocks {
                        @include flex(stretch, stretch);
                        flex-wrap: wrap;
                        gap: 32px;
                        width: 100%;
                        box-sizing: border-box;

                        .block {
                            flex: 1;
                            padding: 32px;
                            @include flex(start, start);
                            flex-direction: column;
                            gap: 32px;
                            border-radius: 32px;
                            background: var(--Background-2, #18181B);

                            p {
                                color: var(--Font-Mine, #FCFCFC);
                                font-size: 18px;
                                font-weight: 500;
                                line-height: normal;
                                margin: 0;

                                @include for-mobile {
                                    text-align: center
                                }
                            }
                        }
                    }

                    .bottom_blocks {
                        @include flex(stretch, stretch);
                        flex-wrap: wrap;
                        gap: 32px;
                        width: 100%;
                        box-sizing: border-box;

                        @include for-mobile {
                            margin-left: 0;
                            box-sizing: border-box;
                        }

                        .block {
                            flex: 1 1;
                            padding: 32px;
                            @include flex(start, start);
                            flex-direction: column;
                            gap: 32px;
                            border-radius: 32px;
                            background: var(--Background-2, #18181B);

                            p {
                                color: var(--Font-Mine, #FCFCFC);
                                font-size: 18px;
                                font-weight: 500;
                                line-height: normal;
                                margin: 0;

                                @include for-mobile {
                                    text-align: center
                                }
                            }
                        }
                    }
                }

                a {
                    display: flex;
                    width: 188px;
                    height: 24px;
                    padding: 12px;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    border-radius: 9999px;
                    border: 2px solid rgba(255, 255, 255, 0.12);
                    background: var(--Other-Purple, #A855F7);
                    text-decoration: none;
                    color: var(--Nav_Button-Font_Active, #FCFCFC);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                }
            }
        }
    }

    .section2 {
        margin-top: 120px;
        width: 100%;
        @include flex(center, center);

        .section2Body {
            @include flex(center, start);
            flex-direction: column;
            gap: 86px;
            width: 73%;

            .title {
                @include flex(center, center);
                width: 100%;

                h1 {
                    color: var(--Neutrals-White, #FFF);
                    text-align: center;
                    font-size: 48px;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .blocks {
                width: 100%;
                @include flex(stretch, center);
                gap: 32px;
                flex-wrap: wrap;

                .block {
                    @include flex(start, start);
                    flex-direction: column;
                    flex: 1;
                    padding: 32px;
                    border-radius: 32px;
                    gap: 32px;
                    background: var(--Background-2, #18181B);

                    .number {
                        color: var(--Font-Mine, #FCFCFC);
                        font-size: 18px;
                        @include flex(center, center);
                        font-weight: 700;
                        line-height: normal;
                        background-color: #A855F7;
                        border-radius: 1000px;
                        width: 40px;
                        height: 40px;
                    }

                    .text {
                        h5 {
                            color: var(--Font-Mine, #FCFCFC);
                            font-size: 16px;
                            font-weight: 700;
                            line-height: normal;
                            margin: 0;
                            margin-bottom: 12px;
                        }

                        p {
                            color: var(--Global-Light-400, #9CA3AF);
                            font-size: 14px;
                            font-weight: 500;
                            line-height: normal;
                            margin: 0;
                            white-space: pre-wrap;
                        }
                    }
                }
            }
        }
    }

    .section3 {
        margin-top: 120px;
        width: 100%;
        @include flex(center, center);

        .section3Body {
            @include flex(center, start);
            flex-direction: column;
            gap: 32px;
            width: 73%;
            border-radius: 32px;
            padding: 48px;
            background: var(--Background-2, #18181B);
            box-sizing: border-box;

            @include for-mobile {
                padding: 16px;
                width: 100% !important;
            }

            .top {
                width: 100%;
                @include flex(start, center);
                flex-wrap: wrap;
                gap: 48px;

                h1 {
                    width: 50.3%;
                    color: var(--Neutrals-White, #FFF);
                    font-size: 48px;
                    font-weight: 500;
                    line-height: 56px;
                    margin: 0;

                    @include for-middle {
                        width: 100%;
                        font-size: 42px;
                        text-align: center;
                    }
                }

                p {
                    width: 49.7%;
                    color: var(--Global-Light-400, #9CA3AF);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0;

                    @include for-middle {
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            .form {
                width: 100%;
                @include flex(center, start);
                gap: 24px;
                flex-direction: column;

                .inputs {
                    width: 100%;
                    @include flex(stretch, space-around);
                    gap: 16px;
                    flex-wrap: wrap;

                    .input_label {
                        @include flex(start, start);
                        flex-direction: column;
                        gap: 12px;
                        width: 100%;

                        @include for-desktop {
                            flex: 1;
                        }

                        label {
                            color: var(--Font-Sub-Font, #51525C);
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0.24px;
                        }

                        input {
                            width: calc(100% - 24px);
                            color: var(--Font-Mine, #FCFCFC);
                            font-family: $font;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px;
                            padding: 16px 8px 16px 16px;
                            border-radius: var(--Corner-Full, 1000px);
                            border: 1px solid var(--Border-Main, #26272B);
                            background: var(--Input-BG, #131316);
                            outline: none;
                        }
                    }
                }

                .textarea_block {
                    width: 100%;
                    @include flex(start, start);
                    flex-direction: column;
                    gap: 12px;

                    label {
                        color: var(--Font-Sub-Font, #51525C);
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.24px;
                    }

                    textarea {
                        width: calc(100% - 32px);
                        padding: 16px;
                        height: 55px;
                        border-radius: 16px;
                        border: 1px solid var(--Border-Main, #26272B);
                        background: var(--Input-BG, #131316);
                        outline: none;
                        resize: none;
                        color: var(--Font-Mine, #FCFCFC);
                        font-size: 14px;
                        font-family: $font;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }

                .link_block {
                    @include flex(center, end);
                    width: 100%;

                    a {
                        @include flex(center, center);
                        width: 188px;
                        height: 24px;
                        padding: 12px;
                        color: var(--Nav_Button-Font_Active, #FCFCFC);
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        text-decoration: none;
                        border-radius: 9999px;
                        border: 2px solid rgba(255, 255, 255, 0.12);
                        background: var(--Other-Purple, #A855F7);
                    }

                    @include for-middle {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .affprogram_page {
        .header {
            background-size: cover;
            background-position: bottom;

            h1 {
                text-align: center;
                font-size: 40px;
            }

            p {
                width: 90%;
                font-size: 18px;
            }
        }

        .section1 {
            .section1Body {
                width: 85%;

                .top {
                    h1 {

                        font-size: 32px;
                    }
                }
            }
        }

        .section2 {
            .section2Body {
                width: 90%;

                .title {
                    h1 {
                        font-size: 32px;
                    }
                }

                .blocks {
                    .block {
                        width: 100%;
                    }
                }
            }
        }

        .section3 {
            .section3Body {
                width: 65%;

                .top {
                    @include flex(center, start);

                    h1 {
                        width: 100%;
                        text-align: center;
                        font-size: 28px;
                    }

                    p {
                        width: 100%;
                        text-align: center;
                    }
                }

                .form {
                    .inputs {
                        flex-wrap: wrap;

                        .input_label {
                            width: 100%;
                        }
                    }

                    .link_block {
                        width: 100%;
                        @include flex(center, center);
                    }
                }
            }
        }
    }
}
@import './theme.scss';
@import './vars.scss';

.topbar {
    width: 100%;
    height: 5.7vh;
    border-radius: 9999px;
    border: 1px solid var(--border-main, #26272B);
    background: var(--background-2, #18181B);
    @include flex(center, space-between);

    .mobile_navbar {
        display: none;
    }

    .logo {
        padding: 0.8vh;
        @include flex(center, start);
        gap: 1.3vh;

        img {
            width: 3.95vh;
            height: 4.1vh;
        }

        h2 {
            color: var(--font-mine, #1F2937);
            font-size: 1.8vh;
            font-weight: 500;
            line-height: 2.4vh;
        }
    }

    .right_side {
        width: 55.8vh;
        @include flex(stretch, stretch);
        gap: 2.4vh;

        .balance {
            border-right: 1px solid var(--border-main, #E5E7EB);
            border-left: 1px solid var(--border-main, #E5E7EB);
            padding-left: 3.2vh;
            flex: 3;
            height: 56px;
            padding-right: 3.2vh;
            @include flex(center, center);

            .inside_balance {
                @include flex(start, start);
                flex-direction: column;

                p {
                    margin: 0;
                    color: var(--font-mine, #1F2937);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }

                span {
                    color: var(--font-sub-font, #9CA3AF);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }
            }
        }

        .buttons {
            @include flex(center, end);
            gap: 0.8vh;
            padding: 0.8vh;

            .connect_wallet{
                @include flex(center,center);
                padding: 0.4vh;
                background: var(--button-alternative-default, #303034);
                border: 1px solid var(--border-button);
                border-radius: 1000px;
                outline: none;
            }
            .theme {
                @include flex(center, center);
                cursor: pointer;

                .moon {
                    padding: 1vh;
                    @include flex(center, center);

                    path {
                        fill: var(--font-sub-font);
                    }

                    &.active {
                        path {
                            fill: var(--font-mine);
                        }
                    }
                }

                .sun {
                    padding: 1vh;
                    @include flex(center, center);

                    path {
                        fill: var(--font-sub-font);
                    }

                    &.active {
                        path {
                            fill: var(--font-mine);
                        }
                    }
                }
            }

            .tooltip {
                @include flex(center,center);
                padding: 0.8vh;
                margin: 0;
                margin-top: 0;
                border-bottom-right-radius: 32px;
                border-bottom-left-radius: 32px;
                background: var(--buttons-alternative-default);
                box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.24);
            }
            .lang_tooltip{
                @include flex(center,center);
                flex-direction: column;
                gap: 0.8vh;
                
            }
            .lang_currency {
                @include flex(center, center);
                padding: 0.8vh;
                gap: 1.2vh;
                border-radius: 9999px;
                background: var(--buttons-alternative-default);

                .lang {
                    @include flex(center, center);
                    cursor: pointer;

                    path {
                        stroke: var(--font-mine)
                    }
                }

                .border {
                    @include flex(center, center);

                    path {
                        stroke: var(--border-main);
                    }
                }

                .currency {
                    @include flex(center, center);
                    cursor: pointer;
                    color: var(--nav-button-font-active);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }
            .noBorderRadius {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            .settings {
                @include flex(center, center);
                border-radius: 9999px;
                padding: 1.2vh;
                background: var(--buttons-alternative-default);
                cursor: pointer;
                transition: all 0.15s ease-in-out;

                path {
                    stroke: var(--font-mine);
                }

            }

            .logout {
                @include flex(center, center);
                border-radius: 9999px;
                padding: 1.2vh;
                background: var(--buttons-alternative-hover);
                cursor: pointer;
                transition: all 0.15s ease-in-out;

                path {
                    stroke: var(--font-mine);
                }
            }

            .logout:hover {
                border-radius: 9999px;
                background: var(--buttons-alternative-default);
            }
        }
    }
}

.paper {
    padding: 9.2vh 1.6vh 1.6vh 6.2vh;
}
.account_drawer {
    width: calc(50.6vh - 3.6vh);
    background-color: var(--background-2);
    position: relative;
    border-radius: 32px;
    border: 1px solid var(--border-main);
    @include flex(center, space-between);
    flex-direction: column;
    height: 89vh;

    .close_icon {
        position: absolute;
        top: 0;
        left: -50px;
        padding: 1.2vh;
        @include flex(center, center);
        border-radius: var(--corner-full, 1000px);
        border: 1px solid var(--border-main);
        background: var(--background-2);
        cursor: pointer;

        path {
            stroke: var(--font-sub-font);
        }
    }
    .top_drawer{
        width: 100%;
        @include flex(center,start);
        flex-direction: column;
        .drawer_header {
            width: calc(100% - 3.2vh);
            border-bottom: 1px solid var(--border-main);
            padding: 1.6vh;
            color: var(--font-mine);
            font-size: 1.4vh;
            font-weight: 600;
            line-height: 1.6vh;
            letter-spacing: 0.28px;
            @include flex(center, start);
            gap: 1.2vh;

            .closeButton {
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.08);
                background: var(--button-alternative-default, #303034);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                padding: 12px;
                align-self: flex-end;
            }

            div {
                @include flex(center, center);
                gap: 12px;

                .icon {
                    padding: 0.8vh;
                    border-radius: 9999px;
                    @include flex(center, center);
                    background: var(--border-main, #26272B);
                    ellipse {
                        stroke: var(--font-mine);
                    }
                }
            }
        }
        .alredyExists{
            width: 100%;
            @include flex(center,center);
            flex-direction: column;
            gap: 3vh;
            h3{
                text-align: center;
                color: var(--font-mine);
            }
            button{
                flex: 1;
                @include flex(center,center);
                border-radius: 8px;
                border: none;
                padding: 1.6vh 4.8vh;
                font-family: $font;
                color: var(--global-dark-25);
                font-size: 1.4vh;
                font-weight: 500;
                line-height: 1.6vh;
                background: var(--other-purple);
                cursor: pointer;
            }
            button:hover{
                transition: all 0.15s ease-in-out;
                background: var(--other-purple);
                box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
            }
        }
        
        .drawer_body {
            width: calc(100%);
            margin-top: 1.6vh;
            @include flex(center, start);
            flex-direction: column;
            gap: 2.4vh;
            .wallet_types{
                width: 100%;
                gap: 1.6vh;
                @include flex(center,start);
                flex-direction: column;
                .type{
                    cursor: pointer;
                    .icon{
                        @include flex(center,center);
                    }
                    width: calc(100% - 4.8vh);
                    padding: 1.2vh;
                    border-radius: 1000px;
                    border: 1px solid var(--border-main);
                    @include flex(center,start);
                    gap: 0.8vh;
                    color: var(--font-mine);
                    &.active{
                        border-color: var(--other-purple);
                    }
                }
            }
            .words_inputs{
                width: 100%;
                gap: 1.6vh;
                @include flex(center,start);
                flex-direction: column;
                span{
                    width: calc(100% - 2.4vh);
                    color: var(--font-mine);
                }
                .three_inputs{
                    width: calc(100% - 2.4vh);
                    @include flex(stretch,stretch);
                    gap: 0.6vh;
                    input{
                        outline: none;
                        border-radius: 1000px;
                        border: 1px solid var(--border-main);
                        background: var(--input-bg);
                        font-family: $font;
                        padding: 1.6vh 0.8vh 1.6vh 1.6vh;
                        width: calc(100% - 2.4vh - 2px);
                        color: var(--font-mine);
                        font-size: 1.4vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                    }
                    input:focus {   
                        border-color: var(--other-purple);
                        // box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px rgba(229, 103, 23, 0.6);
                        outline: 0 none;
                    }
                }
            }
        }
    }
    .drawer_footer{
        width: calc(100% - 3.2vh);
        padding: 1.6vh;
        @include flex(center,center);
        gap: 0.8vh;
        border-top: 1px solid var(--border-main);
        div{
            flex: 1;
            @include flex(center,center);
            border-radius: 9999px;
            background: var(--buttons-alternative-default);
            color: var(--nav-button-font-active);
            font-size: 1.4vh;
            padding: 1.6vh;
            font-weight: 500;
            line-height: 1.6vh;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
        }
        div:hover{
            background: var(--buttons-alternative-hover);
        }
        button{
            flex: 1;
            @include flex(center,center);
            border-radius: 9999px;
            border: none;
            padding: 1.6vh;
            font-family: $font;
            color: var(--global-dark-25);
            font-size: 1.4vh;
            font-weight: 500;
            line-height: 1.6vh;
            background: var(--other-purple);
            cursor: pointer;
        }
        button:hover{
            transition: all 0.15s ease-in-out;
            background: var(--other-purple);
            box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
        }
    }
}

@media (max-width: 600px) {
    .paper{
        padding: 0;
        width: 100%;
    }
    .account_drawer{
        width: calc(100% - 2px);
        .close_icon{
            display: none;
        }
    }
    .topbar {
        width: 98%;
        background: none;
        border: none;

        .mobile_navbar {
            @include flex(center, center);
            width: 4vh;
            height: 4vh;
            border-radius: 9999px;
            background: var(--buttons-alternative-default);

            path {
                stroke: var(--font-mine);
            }
        }

        .logo {
            display: none;
        }

        .right_side {
            width: max-content;
            justify-content: end;

            .balance {
                display: none;
            }

            .buttons {
                .theme {
                    display: none;
                }

                .lang_currency {
                    display: none;
                }
            }
        }
    }
}
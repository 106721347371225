@import '../../styles/vars.scss';
@import '../../styles/theme.scss';

@mixin for-mobile {
    @media screen and (max-width: 830px) {
        @content;
    }
}

.account{
    flex: 12;
    border-radius: 32px;
    border: 1px solid var(--border-main);
    background: var(--background-2);
    @include flex(center,start);
    flex-direction: column;
    .account_title{
        border-bottom: 1px solid var(--border-main);
        padding: 2.4vh;
        width: calc(100% - 4.8vh);
        @include flex(center,space-between);
        gap: 0.8vh;
        color: var(--nav-button-font-active);
        font-size: 1.6vh;
        font-weight: 500;
        line-height: 1.6vh;
        .left_side{
            @include flex(center,start);
            gap: 0.8vh;
            color: var(--nav-button-font-active);
            font-size: 1.6vh;
            font-weight: 500;
            line-height: 1.6vh;
            a{
                color: var(--font-sub-font);
                font-size: 1.6vh;
                font-weight: 500;
                line-height: 1.6vh;
                @include flex(center,start);
                text-decoration: none;
                gap: 0.8vh;
                transition: color 0.15s ease-in-out;
                ellipse{
                    transition: fill 0.15s ease-in-out;
                    fill: var(--font-sub-font);
                }
            }
            a:hover{
                color: var(--nav-button-font-active);
                ellipse{
                    fill: var(--nav-button-font-active);
                }
            }
        }
        .right_side{
            @include flex(center,center);
            gap: 1.2vh;
            border-radius: 9999px;
        }
    }
    .account_body{
        width: calc(100% - 4.8vh);
        padding: 2.4vh;
        height: 100%;
        @include flex(center,start);
        flex-direction: column;
        gap: 3.2vh;
        .account_info_block{
            width: 100%;
            flex: 1.57;
            @include flex(center,center);
            gap: 1.6vh;
            .account_info{
                width: calc(100% - 4.8vh);
                height: calc(100% - 4.8vh);
                flex: 1;
                border-radius: 32px;
                border: 1px solid var(--border-main);
                padding: 2.4vh;
                @include flex(start,start);
                flex-direction: column;
                gap: 1.6vh;
                .info_title{
                    width: 100%;
                    @include flex(start,space-between);
                    .account_name{
                        @include flex(start,center);
                        flex-direction: column;
                        gap: 0.8vh;
                        p{
                            margin: 0;
                            color: var(--font-mine);
                            font-size: 1.6vh;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.6vh;
                        }
                        span{
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                        }
                    }
                    .id{
                        color: var(--font-mine);
                        font-size: 1.6vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                        @include flex(center,end);
                        gap: 0.4vh;
                        span{
                            color: var(--other-purple);
                        }
                    }
                }
                .info_block{
                    width: 100%;
                    position: relative;
                    @include flex(start,space-between);
                    flex-direction: column;
                    border-top: 1px solid var(--border-main);
                    height: 100%;
                    .chart{
                        position: absolute;
                        top: 6vh;
                        left: 0;
                    }
                    .bg_chart{
                        position: absolute;
                        top: 6vh;
                        left: 0px;
                    }
                    .top_block{
                        padding-top: 1.6vh;
                        width: 100%;
                        @include flex(start,space-between);
                        .balance_profit{
                            @include flex(start,start);
                            flex-direction: column;
                            gap: 3.2vh;
                            .balance{
                                label{
                                    color: var(--font-sub-font);
                                    font-size: 1.2vh;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 1.6vh;
                                }
                                h2{
                                    margin: 0;
                                    margin-top: 1.2vh;
                                    color: var(--font-mine);
                                    font-size: 4.8vh;
                                    font-weight: 600;
                                    line-height: 4.8vh;
                                }
                            }
                            .profit{
                                label{
                                    color: var(--font-sub-font);
                                    font-size: 1.2vh;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 1.6vh;
                                }
                                p{
                                    margin: 0;
                                    margin-top: 1.2vh;
                                    color: var(--other-green);
                                    font-size: 2.4vh;
                                    font-weight: 600;
                                    line-height: 2.4vh;
                                }
                            }
                        }
                    }
                    .currency{
                        @include flex(start,start);
                        flex-direction: column;
                        gap: 1.2vh;
                        label{
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                        }
                        span{
                            @include flex(center,start);
                            gap: 0.8vh;
                            color: var(--font-mine);
                            font-size: 1.6vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                        }
                    }
                }
            }
            .account_blocks {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 1.6vh;
                overflow-y: auto;
                justify-content: space-between;
                align-items: start;
                flex: 1.2;
                .account_block {
                    width: calc((100% - 1.6vh) / 2);
                    height: calc((100% - 1.6vh) / 2);
                    padding: 1.6vh;
                    box-sizing: border-box;
                    border-radius: 32px;
                    border: 1px solid var(--border-main);
                    @include flex(start,space-between);
                    flex-direction: column;
                    .mobile_button{
                        display: none;
                    }
                    .top{
                        width: 100%;
                        @include flex(start,start);
                        flex-direction: column;
                        gap: 2.4vh;
                        .title_button{
                            width: 100%;
                            @include flex(center,space-between);
                            p{
                                margin: 0;
                                color: var(--font-mine);
                                font-size: 1.6vh;
                                font-weight: 500;
                                line-height: 1.6vh;
                            }
                            a{
                                @include flex(center,center);
                                gap: 1.2vh;
                                color: var(--nav-button-font-active);
                                font-family: $font;
                                padding: 1.2vh 2.4vh;
                                border-radius: 9999px;
                                background: var(--buttons-alternative-default);
                                border: none;
                                cursor: pointer;
                                font-size: 1.4vh;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1.6vh;
                                transition: all 0.15s ease-in-out;
                                text-decoration: none;
                            }
                            a:hover{
                                background: var(--buttons-alternative-hover);
                            }
                        }
                        .amount{
                            color: var(--font-mine);
                            font-size: 3.2vh;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 4.8vh;

                            @include for-mobile {
                                font-size: 2vh;
                            }
                        }
                    }
                    .select{

                    }
                }
            }
        }
        .transactions{
            width: 100%;
            flex: 0.8;
            .header_cell{
                display: flex;
                align-items: center;
                gap: 0.4vh;
                span{
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 2.4vh;
                    letter-spacing: 0.24px;
                }
                .header_name{
                    display: block !important;
                }
                .sort_icon{
                    @include flex(center,start);
                    flex-direction: column;
                    gap: 0.2vh;
                    padding: 0.3vh 0.5vh;
                    path{
                        stroke: var(--font-sub-font);
                    }
                }
            }
            .transaction_header{
                margin-bottom: 1.6vh;
                @include flex(center,space-between);
                h3{
                    margin: 0;
                    color: var(--font-mine);
                    font-size: 1.6vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }
        }
        .mobile_transactions{
            display: none;
        }
    }
    
}
@media (max-width: 600px) {
    .account{
        width: 100%;
        border: none;
        background: none;
        .account_title{
            margin-top: 24px;
            width: 100%;
            margin-bottom: 24px;
            padding: 0;
            font-size: 14px;
            line-height: 14px;
            border: none;
            .left_side{
                a{
                    font-size: 16px;
                    line-height: 16px;
                    svg{
                        width: 16px;
                        height: 16px;
                    }

                }
                font-size: 16px;
                line-height: 16px;
                svg{
                    width: 16px;
                    height: 16px;
                }
            }
        }
        .account_body{
            padding: 0;
            width: 100%;
            .account_info_block{
                flex-direction: column;
                .bg_chart{
                    top: -13px !important;
                }
                .chart{
                    top: 0 !important;
                }
                .account_info{
                    .info_title{
                        .account_name{
                            p{
                                font-size: 16px;
                            }
                            span{
                                font-size: 12px;
                            }
                        }
                    }
                    .info_block{
                        
                        label{
                            font-size: 12px !important;
                            line-height: 16px !important;
                        }
    
                    }
                }
                .account_blocks{
                    height: max-content;
                    .account_block{
                        padding: 16px;
                        height: max-content;
                        .top{
                            a{
                                display: none !important;
                            }
                        }
                        
                        .mobile_button{
                            margin-top: 12px;
                            width: calc(100% - 4.8vh);
                            @include flex(center,center);
                            gap: 1.2vh;
                            color: var(--nav-button-font-active);
                            font-family: $font;
                            padding: 1.2vh 2.4vh;
                            border-radius: 9999px;
                            background: var(--buttons-alternative-default);
                            border: none;
                            cursor: pointer;
                            font-size: 1.4vh;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.6vh;
                            transition: all 0.15s ease-in-out;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .transactions{
            display: none;
        }
        
        .mobile_transactions{
            width: 100%;
            display: flex !important;
            align-items: start;
            justify-content: start;
            flex-direction: column;
            .label{
                @include flex(center,start);
                gap: 8px;
                color: var(--nav-button-font-active);
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
                path{
                    fill: var(--font-mine);
                }
            }
            .transactions_list{
                margin-top: 3.2vh;
                width: 100%;
                @include flex(center,start);
                flex-direction: column;
                gap: 8px;
                .transaction{
                    width: calc(100% - 48px);
                    padding: 24px;
                    @include flex(center,start);
                    flex-direction: column;
                    border-radius: 32px;
                    background: var(--background-2, #18181B);
                    gap: 16px;
                    .type_status{
                        width: 100%;
                        @include flex(center,space-between);
                        .type{
                            @include flex(center,center);
                            gap: 8px;
                            padding: 4px 8px;
                            border-radius: 9999px;
                            background: rgba(0, 180, 160, 0.12);
                            color: #00B4A0;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px; 
                            letter-spacing: -0.26px;
                            &.with{
                                color: #E9770E;
                                background: rgba(233, 119, 14, 0.08);
                            }
                        }
                        .status{
                            padding: 4px 8px;
                            border-radius: 9999px;
                            background: rgba(82, 165, 229, 0.08);
                            color: var(--Other-Brand-main, #52A5E5);
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px; 
                            letter-spacing: -0.26px;
                        }
                    }
                    .account_amount{
                        width: 100%;
                        @include flex(center,space-between);
                        .account{
                            @include flex(start,start);
                            flex-direction: column;
                            gap: 4px;
                            label{
                                color: var(--font-sub-font, #51525C);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                            }
                            p{
                                color: var(--font-mine, #FCFCFC);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;
                                margin: 0;
                            }
                        }
                        .amount{
                            @include flex(end,start);
                            flex-direction: column;
                            gap: 4px;
                            label{
                                color: var(--font-sub-font, #51525C);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                            }
                            p{
                                @include flex(center,center);
                                gap: 4px;
                                color: var(--Other-Green, #00B4A0);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;
                                margin: 0;
                                &.with{
                                    color: #E9770E;
                                }
                            }
                        }
                    }
                    .id_date{
                        width: 100%;
                        @include flex(center,space-between);
                        .id , .date{
                            color: var(--font-mine, #FCFCFC);
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;
                            margin: 0;
                        }
                    }
                }

            }
        }
    }
}
@import '../../styles/vars.scss';
@import '../../styles/theme.scss';

@mixin for-mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}
@mixin for-middle {
  @media screen and (min-width: 600.1px) and (max-width: 1200px) {
    @content;
  }
}

.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include for-mobile {
    margin-left: 16px;
    margin-right: 16px;
    max-width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.header {
    margin-top: 12px;
    width: 100%;
    @include flex(center, center);
    flex-direction: column;
    height: 380px;
    background: url('../../imgs/Frame 3.png');
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 32px;
  
    @include for-mobile {
      background-size: cover;
      background-position: bottom;
    }

    @include for-middle {
      background-size: cover;
      background-position: bottom;
    }
  
    h1 {
      color: var(--Global-Dark-25, #FCFCFC);
      font-size: 64px;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      margin-bottom: 12px;
  
      @include for-mobile {
          width: 85%;
          text-align: center;
          font-size: 32px;
          font-style: normal;
          hyphens: auto;
          word-wrap: break-word;
      }

      @include for-middle {
        width: 80%;
        text-align: center;
        hyphens: auto;
        word-wrap: break-word;
      }
    }
  
    p {
      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      width: 900px;
  
      @include for-mobile {
          width: 80%;
      }

      @include for-middle {
          width: 80%;
      }
    }
  }

  .info {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-mobile {
      width: 100%;
    }
  }

  .info__container {
    width: 100%;
    margin-top: 80px;

    h1 {
      color: var(--Neutrals-White, #FFF);
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @include for-mobile {
        font-size: 32px;
    }
    }

    p{
      color: var(--Font-Mine, #FCFCFC);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @include for-mobile {
        font-size: 14px;
    }
    }
  }

  .info__container_list {
    display: flex;
    flex-direction: column;
    margin-left: 35%;

    @include for-mobile {
      margin-left: 0;
    }

    @include for-middle {
      align-self: center;
      width: 80%;
      margin-left: 15%;
    }

    p {
      align-self: flex-start;
    }

    li {
      color: var(--Font-Mine, #FCFCFC);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 40%;
      margin-left: 2%;

      @include for-mobile {
        width: 80%;
        margin-left: 7%;
        font-size: 14px;
      }

      @include for-middle {
        width: 80%;
      }
    }
  }


@import './vars.scss';
@import './theme.scss';

.footer {
    margin-top: 120px;
    width: 100%;
    @include flex(center, center);

    .footerBody {
        width: 65%;
        @include flex(center, start);
        flex-direction: column;
        gap: 120px;
        .methods{
            width: 100%;
            flex-wrap: wrap;
            @include flex(start,space-between);
            gap: 12px;

            svg {
                flex: 1;
            }
        }
        .footer_content {
            width: 100%;
            @include flex(center, start);
            flex-direction: column;
            gap: 48px;

            .navigation {
                width: 100%;
                @include flex(center, space-between);

                .logo {
                    width: 40px;
                }

                .links {
                    width: 55%;
                    @include flex(center, end);
                    gap: 32px;
                    a {
                        color: var(--Neutrals-White, #FFF);
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        text-decoration: none;
                    }
                }

                .login_register {
                    @include flex(center, end);
                    gap: 8px;

                    .login {
                        color: var(--Nav_Button-Font_Active, #FCFCFC);
                        font-size: 14px;
                        @include flex(center, center);
                        font-weight: 500;
                        line-height: 16px;
                        width: 72px;
                        padding: 16px 24px;
                        gap: 12px;
                        border-radius: 9999px;
                        background: var(--Background-2, #18181B);
                        text-decoration: none;
                    }

                    .register {
                        border: 2px solid transparent;
                        border-radius: 1000px;
                        background:
                            linear-gradient(to right, #18181B, #18181B),
                            linear-gradient(to right, #A855F78F, #FFFFFF8F);
                        background-clip: padding-box, border-box;
                        background-origin: padding-box, border-box;
                        @include flex(center, center);
                        width: 72px;
                        padding: 14px 22px;
                        font-size: 1.4vh;
                        font-family: $fontLanding;
                        color: var(--Nav_Button-Font_Active, #FCFCFC);
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        text-decoration: none;
                    }
                    .login:hover {
                        transition: all 0.3s ease;
                        background: var(--buttons-alternative-hover);
                        color: var(--nav-button-font-active);
                    }
        
                    .register:hover {
                        transition: all 0.3s ease; /* Плавный переход для ховера */
                        background: var(--buttons-alternative-hover);
                        color: var(--nav-button-font-active);
                    }
                }
            }
            hr{
                width: 100%;
                border: none;
                border-bottom: 1px solid #26272B;
            }
            .warning{
                width: 100%;
                text-align: left;
                color: var(--Font-Sub-Font, #51525C);
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
            }
            .age{
                width: 100%;
                text-align: left;
                color: var(--Font-Sub-Font, #51525C);
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@media (max-width: 1100px) {
    .footer {
        margin-top: 48px;
        padding-bottom: 16px;
        .footerBody {
            width: 90%;
            gap: 84px;
            .methods {
                gap: 12px;
                svg {
                    flex: 1;
                }
            }
            .navigation{
                flex-direction: column;
                gap: 24px;
                .logo{
                    width: 60px !important;
                }
                .links{
                    flex-direction: column;
                }
            }
        }
    }
}